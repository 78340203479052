//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-620:_3436,_2932,_3720,_8556,_2828,_7516,_8896,_1660;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-620')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-620', "_3436,_2932,_3720,_8556,_2828,_7516,_8896,_1660");
        }
      }catch (ex) {
        console.error(ex);
      }